import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import CampAccordions from "./CampAccordions";
import { COLORS } from "../../../Styles/colors";
import { useAppSelector } from "../../../Redux/app/hooks";

const AdminReprocessResults = () => {
  // ----- Contexts -----
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ---- States -----
  const [data, setData] = useState({
    campaigns: [],
  });
  const [btnLoading, setBtnLoading] = useState(false);

  // ----- Variables -----

  // ----- Functions -----
  const handleSubmit = async (campaignUUID) => {
    // get elements
    const errorText = document.getElementById(`input-err-${campaignUUID}`);
    // const cmdOutputText = document.getElementById(`camp-cmd-${campaignUUID}`);

    // lock button & text box
    setBtnLoading(true);

    // if there are errors, clear them
    errorText.innerText = "";
    errorText.style.display = "none";

    // create post data object to send
    const postData = {
      campaign: campaignUUID,
      // cmd_output: cmdOutputText.value,
    };

    // send request
    const res = await postRequest(
      `/react/api/admin-edit-cmd-output/${campaignUUID}`,
      accessToken,
      postData
    );
    if (res.status !== 200) {
      errorText.style.display = "block";
      errorText.innerText =
        "Failed to process, check your input and try again.";
    } else {
      errorText.style.display = "block";
      errorText.innerText = "success";
      errorText.style.color = "green";
    }

    // unlock button.
    setBtnLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        "/react/api/admin-reprocess-results",
        accessToken
      );
      if (res.status === 200) {
        setData(res.data);
      }
    };

    getData();
  }, [current_customer, accessToken]);

  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Administration</Typography>
        <Typography color="text.primary">Reprocess Results</Typography>
      </Breadcrumbs>

      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", color: COLORS.error.main }}
      >
        Editing this will affect any existing results for that campaign and
        reset any work done. Output must be Base64 encoded. Make sure you're
        editing the correct campaign!
      </Typography>

      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        Please use SLED formatting.
      </Typography>

      <Paper sx={{ padding: 3 }}>
        <Stack>
          <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
            CAMPAIGNS
          </Typography>
          <Divider />
          {data.campaigns.length > 0 ? (
            <CampAccordions
              campaigns={data.campaigns}
              btnLoading={btnLoading}
              handleSubmit={handleSubmit}
            />
          ) : (
            <Box sx={{ p: 3, textAlign: "center" }}>
              <CircularProgress />
            </Box>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default AdminReprocessResults;
